import styled from 'styled-components';

export const Container = styled.div`
position: fixed;
left: 0;
right: 0;
bottom: 0;
top: 0;
background-color: rgba(0, 0, 0, 0.4);
display: flex;
justify-content: center;
align-items: center;
z-index: 10;

`;

export const Content = styled.div`
background-color: #fff;
flex-direction: column;
width: 400px;
overflow: auto;
box-shadow: 0px 0px 20px #555;
display: flex;
border-radius: 10px;
color: #000;
`;

export const Header = styled.div`
background-color: ${props => props.color};
height: 60px;
width: 100%;
display: flex;
justify-content: space-evenly;
align-items: center;
font-size: 18px;
font-weight: bold;
`;

export const Body = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
flex: 1;
`;

export const Text = styled.p`
font-size: 14px;
padding: 15px 30px;
text-align: justify;
color: #000;
a {
    text-decoration: none;
    color: #000000
    }
`;

export const Button = styled.button`
padding: 12px;
width: 140px;
height: 40px;
background-color: #e0e0e0;
color: #333;
border: none;
border-radius: 5px;
font-weight: bold;
box-shadow: 0px 3px 3px #ccc;
cursor: pointer;
`;

export const Footer = styled.div`
height: 60px;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
font-size: 20px;
`;