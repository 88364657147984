import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";

import * as C from './styled'

import Api from '../../services/api'
import { UserContext } from '../../contexts/UserContext';


import TaskIcon from '@mui/icons-material/Task';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import BusinessIcon from '@mui/icons-material/Business';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export const Contracts = () => {
    const navigate = useNavigate();

    const { state: user } = useContext(UserContext)

    const [list, setList] = useState([]);
    const [filteredList, setFilteredList] = useState([])
    const [switchButton, setSwitchButton] = useState(false)
    const [state, setState] = useState('')
    const [empty, setEmpty] = useState(null)
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const [showReply, setShowReply] = useState(false)
    const [selectedTicket, setSelectedTicket] = useState('')


    const handleSearch = (event) => {
      let value = event.target.value.toLowerCase();
      let result = [];

      result = list.filter((data) => {
      if (/\d/.test(value)) {
      return data.TicketNumber.toString().search(value) != -1; }
      else {
        return data.Title.toLowerCase().search(value) != -1; }
      });

      setFilteredList(result);
      }


    const ShowReplyClick = () => {
        setShowReply(true)
        document.getElementById('view')?.scrollIntoView({ behavior: 'smooth' });
    }

    const RefreshClick = (StateTicket) => {
      document.getElementById('search').value = '';
      setFilteredList([])
      setState(StateTicket)
      }

      const TicketClick = (id) => {
        setSelectedTicket(id)
        setModal(true)
    }

    const CloseModalClick = () => {
      setShowReply(false)
      setModal(false)
    }


    const getCustomerTickets = async () => {
      setEmpty(false)
      setFilteredList([])
      setLoading(true)
      let res2 = await Api.getCustomerTickets(state, switchButton ? user.user : '');
      if (res2.Ticket) {
          setEmpty(false)
          setList(res2.Ticket)
          setFilteredList(res2.Ticket)
      } else {
          setEmpty(true)
      }
      setLoading(false);
    }

    useEffect(()=>{

      getCustomerTickets();

      }, [state, switchButton]);


    return (
        <C.Container>
            <C.Header>
                <C.TitleArea>
                <TaskIcon  style={{marginRight: "7px"}}/>
                <h2>| Contratos</h2>
                </C.TitleArea>

                <C.SearchArea>
                <C.SwitchArea>
                  <BusinessIcon style={{ paddingBottom: '2px', borderBottom: switchButton ? 'none' : '3px solid #2E8B57' }}/>
                  <C.IconButton onClick={() => setSwitchButton(switchButton === false ? true : false)}>
                   <PublishedWithChangesIcon fontSize="small"/>
                  </C.IconButton>
                  <AccountCircleIcon style={{ paddingBottom: '2px', borderBottom: switchButton ? '3px solid #2E8B57' : 'none' }}/>
                </C.SwitchArea>
                 <C.StateArea>
                  {state !== '' &&
                  <CloseIcon onClick={() => RefreshClick('')}/>
                  }
                  <TaskIcon fontSize={state === 'new' ? 'large' :'medium'} onClick={() => RefreshClick('new')} style={{color: '#1E90FF', cursor: 'pointer'}}/>
                  <TaskIcon fontSize={state === 'open' ? 'large' :'medium'} onClick={() => RefreshClick('open')} style={{color: '#2E8B57', cursor: 'pointer'}}/>
                  <TaskIcon fontSize={state === 'pending reminder' ? 'large' :'medium'} onClick={() => RefreshClick('pending reminder')}  style={{color: '#FCC703', cursor: 'pointer'}}/>
                  <TaskIcon fontSize={state === 'closed' ? 'large' :'medium'} onClick={() => RefreshClick('closed')}  style={{color: '#E95839', cursor: 'pointer'}}/>
                  </C.StateArea>
                  <SearchIcon />
                  <C.SearchInput id='search' disabled={state !== '' ? true : false} onChange={(event) => handleSearch(event)} >
                  </C.SearchInput>
                </C.SearchArea>
            </C.Header>
            <C.Body>
                {loading &&  
                <C.Logo src={"/loading.svg"} />
                }  
                {empty &&
                <C.CardHeaderTitle style={{fontSize: '20px', marginTop: '150px'  }}>
                      Não existem itens para o status selecionado
                </C.CardHeaderTitle>
                }
         

            </C.Body>
            {modal !== false &&
            <C.ModalContainer>
              <C.Options>
                <C.Button onClick={ShowReplyClick} style={{backgroundColor: list[selectedTicket].StateType === 'closed' ? '#2E8B57' : '#1E90FF'}}>{list[selectedTicket].StateType === 'closed' ? 'Reabrir' : 'Responder'}</C.Button>
                {list[selectedTicket].StateType !== 'closed' &&
                <C.Button style={{backgroundColor: '#E95839'}}>Encerrar Chamado</C.Button>
                }
                  <C.CardHeaderLogo style={{ }}>
                     <HighlightOffIcon onClick={CloseModalClick} style={{color: '#333' }} fontSize="large"/>
                   </C.CardHeaderLogo>
              </C.Options>
            }
              <C.Content>
              <C.Header id='view' style={{justifyContent: 'space-around', marginTop: '35px' }}>


                    <C.CardHeaderTitle style={{ fontSize: '20px', marginLeft: '10px'}}>
                   Chamado #{list[selectedTicket].TicketNumber} - {list[selectedTicket].Title}
                 </C.CardHeaderTitle>

              </C.Header>
              <C.ModalBody>
                <C.ModalDetails>

                      <C.CardBodyText style={{width: '180px', fontSize: '15px', borderBottom: list[selectedTicket].StateType === 'open' ? '5px solid #2E8B57' :
                                                                            list[selectedTicket].StateType === 'new' ? '5px solid #1E90FF' :
                                                                            list[selectedTicket].StateType === 'pending reminder' ? '5px solid #FCC703' : '5px solid #E95839',
                                                              backgroundColor: list[selectedTicket].State === 'Em Atendimento' ? '#F1FFF3' :
                                                              list[selectedTicket].State === 'Aguardando Cliente' ? '#FCF4DB' : '#f5f5f5',
                                                                               padding: '7px 12px' }}>
                    <strong>{list[selectedTicket].State==='new' ? 'Novo' : list[selectedTicket].State==='open' ? 'Aberto' :
                                                           list[selectedTicket].State==='closed successful' ? 'Fechado com exito' :
                                                           list[selectedTicket].State==='closed unsuccessful' ? 'Fechado sem exito' :
                                                          list[selectedTicket].State}</strong>

                    </C.CardBodyText>

                      <C.CardBodyStatusText >
                      <strong>Criado: </strong>{list[selectedTicket].Created}
                      </C.CardBodyStatusText>
                      <C.CardBodyStatusText>
                      <strong>Fila</strong>: {list[selectedTicket].Queue}
                      </C.CardBodyStatusText>
                      <C.CardBodyStatusText style={{backgroundColor: list[selectedTicket].SLA === 'EM1-R' && 'EM2-R' ? '#E95839' : '#1E90FF', padding: '3px 7px', color: '#fff'  }}>
                      <strong>SLA: {list[selectedTicket].SLA}</strong>
                      </C.CardBodyStatusText>
                      <C.CardBodyStatusText>
                      <strong>Tipo: </strong> {list[selectedTicket].Type}
                      </C.CardBodyStatusText>
                      <C.CardBodyStatusText>
                      <strong>Serviço: </strong> {list[selectedTicket].Service}
                      </C.CardBodyStatusText>
                      <C.CardBodyStatusText>
                      <strong>Criado por: </strong> {list[selectedTicket].CustomerUserID}
                      </C.CardBodyStatusText>
                      <C.CardBodyStatusText>
                      <strong>Responsável: </strong> {list[selectedTicket].Responsible}
                      </C.CardBodyStatusText>
                      <C.CardBodyText style={{borderLeft: '5px solid #2E8B57', width: '180px' }}>
                      Prazo para inicio: {list[selectedTicket].EscalationDestinationDate}
                      </C.CardBodyText>
                      <C.CardBodyText style={{borderLeft: '5px solid #E95839', width: '180px' }}>
                      Prazo para solução: {list[selectedTicket].SolutionTimeDestinationDate}
                      </C.CardBodyText>
                </C.ModalDetails>
                <C.ModalContentBody>
                {showReply !== false &&
                <C.Article>
                  <C.ArticleHeader  style={{height: '60px', backgroundColor: list[selectedTicket].StateType === 'closed' ? '#BDF5D6' : '#C8E4FF'}}>
                  <C.CardHeaderInfo style={{marginLeft: '10px'}}>
                      <C.CardHeaderNumber style={{fontSize: '14px', fontWeight: 'normal'}}>{list[selectedTicket].StateType === 'closed' ? 'Este chamado esta fechado! Envie uma interação para reabri-lo.' : 'Enviar Resposta:'}</C.CardHeaderNumber>
                      </C.CardHeaderInfo>
                  </C.ArticleHeader>
                  <C.ArticleBody style={{padding: '10px'}}>
                    <C.ArticleReply/>
                  </C.ArticleBody>
                  <C.ArticleFooter>
                    teste
                  </C.ArticleFooter>
                </C.Article>}
                {list[selectedTicket].Article.map((article, key)=>(
                    <C.Article style={{borderLeft: article.SenderType !== 'customer' ? '3px solid #FFB400' : ''}} key={key}>
                    <C.ArticleHeader style={{justifyContent: 'space-between'}}>
                    <C.CardHeaderLogo style={{padding:'10px', borderRadius: '10px', backgroundColor: '#f1f1f1', marginLeft: '20px', marginRight: '-30px'}}>
                      {article.ArticleNumber}
                    </C.CardHeaderLogo>
                      <C.CardHeaderInfo >
                      <C.CardHeaderNumber>{article.From}</C.CardHeaderNumber>
                      <C.CardHeaderTitle >{ article.Subject}</C.CardHeaderTitle>
                      </C.CardHeaderInfo>
                      <C.CardHeaderNumber style={{marginRight: '10px', fontWeight: 'normal', fontSize: '12px'}} >
                      { article.CreateTime}
                      </C.CardHeaderNumber>
                    </C.ArticleHeader>
                    <C.ArticleBody>
                    <div dangerouslySetInnerHTML={{ __html: article.Body.replace(/\n/g, "<br />") }}></div>
                    </C.ArticleBody>
                    </C.Article>
                  )).reverse()}

                 </C.ModalContentBody>
                 </C.ModalBody>
              </C.Content>

            </C.ModalContainer>

            }

        </C.Container>

    );
}
