import React from 'react';
import * as C from './styled'

import CloseIcon from '@mui/icons-material/Close';



export const Alert = ({textAlert, setAlertClick, colorAlert}) => {

    const CloseClick = () => {
        setAlertClick('')
    }

    return (
        <C.Container status={textAlert} color={colorAlert} onClick={CloseClick}>
               {textAlert !== '' && 
               <CloseIcon/>
               }
                {textAlert}
        </C.Container>
    );
}
