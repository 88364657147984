import React from 'react';
import * as C from './styled'


export const ModalAlert = ({modalAlert, setModalAlertClick, colorModalAlert, modalTitle}) => {

    const CloseClick = () => {
        setModalAlertClick('')
    }

    return (
        <C.Container >
          <C.Content status={modalAlert}>
            <C.Header color={colorModalAlert} >
            {modalTitle ? modalTitle : 'Atenção'}  
            </C.Header>
            <C.Body>
            <C.Text>
            <div dangerouslySetInnerHTML={{ __html: modalAlert }}></div>
            </C.Text>
            </C.Body>
            <C.Footer>
              <C.Button onClick={CloseClick}>
              Fechar
              </C.Button>
            </C.Footer>
          </C.Content>
        </C.Container>
    );
}
