import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import 'react-tooltip/dist/react-tooltip.css'

import * as G from '../../styles/global'
import * as C from './styled'

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArticleIcon from '@mui/icons-material/Article';
import BackupIcon from '@mui/icons-material/Backup';
import BusinessIcon from '@mui/icons-material/Business';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ChatBubbleRoundedIcon from '@mui/icons-material/ChatBubbleRounded';
import CloseIcon from '@mui/icons-material/Close';
import CloudIcon from '@mui/icons-material/Cloud';
import DescriptionIcon from '@mui/icons-material/Description';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import HomeIcon from '@mui/icons-material/Home';
import MailIcon from '@mui/icons-material/Mail';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import TaskIcon from '@mui/icons-material/Task';
import TodayIcon from '@mui/icons-material/Today';

 
/// DATA TABLE - ICONS - START ////////////////////////////////////////////////
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
/// DATA TABLE - ICONS - END //////////////////////////////////////////////////

const dataTable =  [
  {
    code: '2377',
    description: 'DataCenter',
    entity: 'DataCenter',
    accountPlan: 'Licença de Software',
    payment: 'Boleto Bancário',
    date: '19/05/2023',
    amount: '651,37',
    status: 'Confirmado',
  },
  {
    code: '2345',
    description: 'Aluguel',
    entity: 'Locador sem Nome',
    accountPlan: 'Aluguel + IPTU',
    payment: 'Boleto Bancário',
    date: '01/05/2023',
    amount: '800,00',
    status: 'Confirmado',
  },
  {
    code: '2499',
    description: 'Telefone + Internet',
    entity: 'Provedor',
    accountPlan: 'Telefonia e Internet',
    payment: 'PIX',
    date: '09/05/2023',
    amount: '100.000,50',
    status: 'Cancelado',
  },
  {
    code: '2455',
    description: 'DAS',
    entity: 'Governo',
    accountPlan: 'Impostos',
    payment: 'Tranferência',
    date: '23/05/2023',
    amount: '1.050,50',
    status: 'Em aberto',
  },
  {
    code: '2377',
    description: 'DataCenter',
    entity: 'DataCenter',
    accountPlan: 'Licença de Software',
    payment: 'Boleto Bancário',
    date: '19/05/2023',
    amount: '651,37',
    status: 'Confirmado',
  },
  {
    code: '2345',
    description: 'Aluguel',
    entity: 'Locador sem Nome',
    accountPlan: 'Aluguel + IPTU',
    payment: 'Boleto Bancário',
    date: '01/05/2023',
    amount: '800,00',
    status: 'Confirmado',
  },
  {
    code: '2499',
    description: 'Telefone + Internet',
    entity: 'Provedor',
    accountPlan: 'Telefonia e Internet',
    payment: 'PIX',
    date: '09/05/2023',
    amount: '100.000,50',
    status: 'Cancelado',
  },
  {
    code: '2455',
    description: 'DAS',
    entity: 'Governo',
    accountPlan: 'Impostos',
    payment: 'Tranferência',
    date: '23/05/2023',
    amount: '1.050,50',
    status: 'Em aberto',
  },
]


export const Template = () => {
  const navigate = useNavigate();

  const [switchButton, setSwitchButton] = useState(false)
  const [list, setList] = useState([])
  const [state, setState] = useState('')
  
  /// DATA TABLE - STATES - START ///////////////////////////////////////////////
  const [filteredList, setFilteredList] = useState(dataTable)
  const [order, setOrder] = useState('')
  const [activeOrder, setActiveOrder] = useState('')
  /// DATA TABLE - STATES - END /////////////////////////////////////////////////
  
  /// DATA TABLE - FUNCTIONS - START ////////////////////////////////////////////
  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase()
    let newArray = dataTable.filter((data) => {
     if (/\d/.test(value)) {
        return data.code.toString().search(value) !== -1;
      }
      else {
        return data.description.toLowerCase().search(value) !== -1 || 
               data.entity.toLowerCase().search(value) !== -1 ||
               data.payment.toLowerCase().search(value) !== -1

      }
    })
    setFilteredList(newArray);
  }

  const OrderClick = (field) => {
    setActiveOrder(field)
    let result = filteredList.sort(function(a, b){
      if (order === 'asc'){
        return a[field] < b[field] ? -1 : true
      } else {
        return a[field] > b[field] ? -1 : true
      }
    })
    order === 'asc' ? setOrder('desc') : setOrder('asc')
    setFilteredList(result)
  }
  /// DATA TABLE - FUNCTIONS - END //////////////////////////////////////////////


  const RefreshClick = (StateTicket) => {
    document.getElementById('search').value = '';
    setFilteredList([])
    setState(StateTicket)
  }


  return (
    <C.Container>
      <G.Header>
        <G.HeaderTitleArea>
          <TaskIcon fontSize='large' style={{ marginRight: "7px" }} />
          <G.MainTitle>| Template</G.MainTitle>
        </G.HeaderTitleArea>
      </G.Header>
      <G.Body>
        <G.Box>
          <G.BoxHeader>
            <G.SubTitle>Botões</G.SubTitle>
          </G.BoxHeader>
          <G.BoxBody>
            <G.Button style={{ backgroundColor: '#2E8B57' }}>Success</G.Button>
            <G.Button style={{ backgroundColor: '#E95839' }}>Danger</G.Button>
            <G.Button style={{ backgroundColor: '#FCC703' }}>Warning</G.Button>
            <G.Button style={{ backgroundColor: '#1E90FF' }}>Edit</G.Button>
            <G.Button style={{ backgroundColor: '#FFB400' }}>Primary</G.Button>
            <G.Button style={{ backgroundColor: '#333' }}>Secundary</G.Button>
            <G.IconButton style={{ backgroundColor: '#2E8B57' }}><HomeIcon fontSize="small" /></G.IconButton>
            <G.IconButton style={{ backgroundColor: '#E95839' }}><BackupIcon fontSize="small" /></G.IconButton>
            <G.IconButton style={{ backgroundColor: '#FCC703' }}><ChatBubbleRoundedIcon fontSize="small" /></G.IconButton>
            <G.IconButton style={{ backgroundColor: '#1E90FF' }}><BackupIcon fontSize="small" /></G.IconButton>
            <G.IconButton style={{ backgroundColor: '#FFB400' }}><ArticleIcon fontSize="small" /></G.IconButton>
            <G.IconButton style={{ backgroundColor: '#333' }}><HomeIcon fontSize="small" /></G.IconButton>
          </G.BoxBody>
        </G.Box>
        <G.Box>
          <G.BoxHeader>
            <G.SubTitle>Botões | Outline</G.SubTitle>
          </G.BoxHeader>
          <G.BoxBody>
            <G.ButtonOutLine style={{ border: '1px solid #2E8B57', color: '#2E8B57' }}>Success</G.ButtonOutLine>
            <G.ButtonOutLine style={{ border: '1px solid #E95839', color: '#E95839' }}>Danger</G.ButtonOutLine>
            <G.ButtonOutLine style={{ border: '1px solid #FCC703', color: '#FCC703' }}>Warning</G.ButtonOutLine>
            <G.ButtonOutLine style={{ border: '1px solid #1E90FF', color: '#1E90FF' }}>Edit</G.ButtonOutLine>
            <G.ButtonOutLine style={{ border: '1px solid #FFB400', color: '#FFB400' }}>Primary</G.ButtonOutLine>
            <G.ButtonOutLine style={{ border: '1px solid #333', color: '#333' }}>Secundary</G.ButtonOutLine>
          </G.BoxBody>
        </G.Box>
        <G.Box>
          <G.BoxHeader>
            <G.SubTitle>Cores do Tema</G.SubTitle>
          </G.BoxHeader>
          <G.BoxBody>
            <G.Button style={{ backgroundColor: '#2E8B57' }}>#2E8B57</G.Button>
            <G.Button style={{ backgroundColor: '#E95839' }}>#E95839</G.Button>
            <G.Button style={{ backgroundColor: '#FCC703' }}>#FCC703</G.Button>
            <G.Button style={{ backgroundColor: '#1E90FF' }}>#1E90FF</G.Button>
            <G.Button style={{ backgroundColor: '#FFB400' }}>#FFB400</G.Button>
            <G.Button style={{ backgroundColor: '#333' }}>#333</G.Button>
          </G.BoxBody>
          <G.BoxBody>
            <G.Button style={{ backgroundColor: '#BDF5D6', color: '#000' }}>#BDF5D6</G.Button>
            <G.Button style={{ backgroundColor: '#FCAC9B', color: '#000' }}>#FCAC9B</G.Button>
            <G.Button style={{ backgroundColor: '#FFEEAD', color: '#000' }}>#FFEEAD</G.Button>
            <G.Button style={{ backgroundColor: '#C8E4FF', color: '#000' }}>#C8E4FF</G.Button>
          </G.BoxBody>
        </G.Box>
        <G.Box>
          <G.BoxHeader>
            <G.SubTitle>Botões | Underline</G.SubTitle>
          </G.BoxHeader>
          <G.BoxBody style={{ justifyContent: "space-around" }} >
            <G.ButtonUnderLine > <TaskIcon style={{ marginRight: "7px" }} />Botão Padrão</G.ButtonUnderLine>
            <G.SpecialButton> <AddCircleIcon style={{ marginRight: "7px" }} fontSize="large" /> Botão Especial</G.SpecialButton>
          </G.BoxBody>
        </G.Box>
        <G.Box>
          <G.BoxHeader>
            <G.SubTitle>Botões | Download</G.SubTitle>
          </G.BoxHeader>
          <G.BoxBody>
            <G.DownloadButton style={{ backgroundColor: '#E95839', minWidth: '70px' }}>PDF</G.DownloadButton>
            <G.DownloadButton style={{ backgroundColor: '#34495e', minWidth: '220px', marginLeft: '-20px' }}>banner.pdf</G.DownloadButton>
            <G.DownloadButton style={{ backgroundColor: '#2E8B57', minWidth: '30px', marginLeft: '-20px' }}><FileDownloadIcon /></G.DownloadButton>
          </G.BoxBody>
        </G.Box>
        <G.Box>
          <G.BoxHeader>
            <G.SubTitle>Filtros</G.SubTitle>
          </G.BoxHeader>
          <G.BoxBody>
            <G.OptionsArea>
              <G.SwitchArea>
                <BusinessIcon style={{ paddingBottom: '2px', borderBottom: switchButton ? 'none' : '3px solid #2E8B57' }} />
                <G.CircleButton onClick={() => setSwitchButton(switchButton === false ? true : false)}>
                  <PublishedWithChangesIcon fontSize="small" />
                </G.CircleButton>
                <AccountCircleIcon style={{ paddingBottom: '2px', borderBottom: switchButton ? '3px solid #2E8B57' : 'none' }} />
              </G.SwitchArea>
              <G.StateArea>
                {state !== '' &&
                  <CloseIcon onClick={() => RefreshClick('')} />
                }
                <TaskIcon fontSize={state === 'new' ? 'large' : 'medium'} onClick={() => RefreshClick('new')} style={{ color: '#1E90FF', cursor: 'pointer' }} />
                <TaskIcon fontSize={state === 'open' ? 'large' : 'medium'} onClick={() => RefreshClick('open')} style={{ color: '#2E8B57', cursor: 'pointer' }} />
                <TaskIcon fontSize={state === 'pending reminder' ? 'large' : 'medium'} onClick={() => RefreshClick('pending reminder')} style={{ color: '#FCC703', cursor: 'pointer' }} />
                <TaskIcon fontSize={state === 'closed' ? 'large' : 'medium'} onClick={() => RefreshClick('closed')} style={{ color: '#E95839', cursor: 'pointer' }} />
              </G.StateArea>
              <SearchIcon />
              <G.SearchInput id='search' disabled={state !== '' ? true : false} onChange={(event) => handleSearch(event)} >
              </G.SearchInput>
            </G.OptionsArea>
          </G.BoxBody>
        </G.Box>

        <G.Box>
          <G.BoxHeader>
            <G.BoxHeaderTitleArea>
              <CloudIcon fontSize='large' style={{ marginRight: "7px" }} />
              <G.SubTitle>CLOUD</G.SubTitle>
            </G.BoxHeaderTitleArea>
            <G.BoxHeaderOptionsArea>
              <G.InfoArea style={{ minWidth: '240px' }}>
                <CalendarMonthIcon style={{ fontSize: '30px', marginRight: "7px" }} />
                <G.DistacInfoText style={{ marginRight: "7px" }}>Ciclo Faturamento:</G.DistacInfoText>
                <G.InfoText>21 à 20</G.InfoText>
              </G.InfoArea>
              <G.InfoArea style={{ minWidth: '240px' }}>
                <TodayIcon style={{ fontSize: '30px', marginRight: "7px" }} />
                <G.DistacInfoText style={{ marginRight: "7px" }}>Dia Pagamento:</G.DistacInfoText>
                <G.InfoText>20</G.InfoText>
              </G.InfoArea>
              <G.InfoArea style={{ minWidth: '240px' }}>
                <MonetizationOnIcon style={{ fontSize: '30px', marginRight: "7px" }} />
                <G.DistacInfoText style={{ marginRight: "7px" }}>Método Pagamento:</G.DistacInfoText>
                <G.InfoText>Boleto</G.InfoText>
              </G.InfoArea>
            </G.BoxHeaderOptionsArea>
          </G.BoxHeader>
          <G.BoxBody>
            <G.Card>
              <G.CardHeader>
                <MailIcon style={{ fontSize: '30px', marginRight: "7px" }} />
                <G.SmallTitle>MAIL CLOUD CORPORATIVO 2000D (Mensal) | SaaS</G.SmallTitle>
              </G.CardHeader>
              <G.CardBody>
                <G.Text >
                  • Armazenamento de 2.000GB (uso total). • Hospedagem e banco de dados (até 05 domínios) somente para sites institucionais. • Máximo de 380 contas. • Backup local e remoto com 10 dias de redundância para e-mails e 3 dias para sites.
                </G.Text>
              </G.CardBody>
              <G.CardFooter>
                <G.Button style={{ backgroundColor: '#E95839' }}>Danger</G.Button>
                <G.Button style={{ backgroundColor: '#2E8B57' }}>Success</G.Button>
              </G.CardFooter>
            </G.Card>
            <G.Card>
              <G.CardHeader>
                <MailIcon style={{ fontSize: '30px', marginRight: "7px" }} />
                <G.SmallTitle>MAIL CLOUD CORPORATIVO 2000D (Mensal) | SaaS</G.SmallTitle>
              </G.CardHeader>
              <G.CardBody>
                <G.Text >
                  • Armazenamento de 2.000GB (uso total). • Hospedagem e banco de dados (até 05 domínios) somente para sites institucionais. • Máximo de 380 contas. • Backup local e remoto com 10 dias de redundância para e-mails e 3 dias para sites.
                </G.Text>
              </G.CardBody>
              <G.CardFooter>
                <G.Button style={{ backgroundColor: '#E95839' }}>Danger</G.Button>
                <G.Button style={{ backgroundColor: '#2E8B57' }}>Success</G.Button>
              </G.CardFooter>
            </G.Card>
          </G.BoxBody>
          <G.BoxFooter>
            <G.OptionsArea>
              <G.BoxHeaderOptionsArea>
                <G.InfoArea style={{ minWidth: '300px' }}>
                  <DescriptionIcon style={{ fontSize: '30px', marginRight: "7px" }} />
                  <G.DistacInfoText style={{ marginRight: "7px" }}>CONTRATO</G.DistacInfoText>
                  <G.InfoText>JEC-2023-CPSC-00098</G.InfoText>
                </G.InfoArea>
              </G.BoxHeaderOptionsArea>
            </G.OptionsArea>
          </G.BoxFooter>
        </G.Box>
        
        <G.Box>
          <G.TableHeader style={{ justifyContent: 'space-between' }}>
          <G.SubTitle>DataTable</G.SubTitle>
            <G.InfoArea style={{ justifyContent: 'space-between' }}>
              <SearchIcon />
              <G.SearchInput id='search' onChange={(event) => handleSearch(event)} />
              <G.Button style={{ backgroundColor: '#1E90FF', marginLeft:'100px'}}>Adicionar</G.Button>
            </G.InfoArea>
          </G.TableHeader>
          <G.TableHeader>
            <G.OptionsArea>
              <G.FieldArea size={'50px'}><G.DistacInfoText>Código</G.DistacInfoText></G.FieldArea>
              <G.FieldArea size={'130px'} onClick={() => OrderClick('description')}><G.DistacInfoText style={{  }}>Descrição</G.DistacInfoText>
                {(activeOrder === '' || activeOrder === 'description') &&
                  <>
                    {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                  </>
                }
              </G.FieldArea>
              <G.FieldArea size={'100px'} onClick={() => OrderClick('entity')}><G.DistacInfoText>Entidade</G.DistacInfoText>
                {(activeOrder === '' || activeOrder === 'entity') &&
                  <>
                    {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                  </>
                }
              </G.FieldArea>
              <G.FieldArea size={'100px'} onClick={() => OrderClick('accountPlan')}><G.DistacInfoText>Plano de Contas</G.DistacInfoText>
                {(activeOrder === '' || activeOrder === 'accountPlan') &&
                  <>
                    {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                  </>
                }
              </G.FieldArea>
              <G.FieldArea size={'100px'}><G.DistacInfoText>Pagamento</G.DistacInfoText></G.FieldArea>
              <G.FieldArea size={'70px'}><G.DistacInfoText>Data</G.DistacInfoText></G.FieldArea>
              <G.FieldArea size={'100px'}><G.DistacInfoText>Valor Total</G.DistacInfoText></G.FieldArea>
              <G.FieldArea size={'100px'}><G.DistacInfoText>Situação</G.DistacInfoText></G.FieldArea>
              <G.FieldArea size={'100px'}><G.DistacInfoText>Ações</G.DistacInfoText></G.FieldArea>
            </G.OptionsArea>
          </G.TableHeader>
          <G.TableBody style={{ flexDirection: 'column', height: '400px', justifyContent: 'start', marginTop: '0px', overflowY: 'scroll'}}>
            {filteredList.map((item, key) => (
              <G.TableArea key={key}>
                <G.FieldArea size={'50px'}><G.Text>{item.code}</G.Text></G.FieldArea>
                <G.FieldArea size={'130px'}><G.Text>{item.description}</G.Text></G.FieldArea>
                <G.FieldArea size={'100px'}><G.Text>{item.entity}</G.Text></G.FieldArea>
                <G.FieldArea size={'100px'}><G.Text>{item.accountPlan}</G.Text></G.FieldArea>
                <G.FieldArea size={'100px'}><G.Text>{item.payment}</G.Text></G.FieldArea>
                <G.FieldArea size={'70px'}><G.Text>{item.date}</G.Text></G.FieldArea>
                <G.FieldArea size={'100px'} style={{ justifyContent: 'end'  }}><G.Text>{item.amount}</G.Text></G.FieldArea>
                <G.FieldArea size={'100px'}><G.Text>{item.status}</G.Text></G.FieldArea>
                <G.FieldArea size={'100px'} style={{ justifyContent: 'space-around' }}>
                  <G.Text>
                    <G.IconButton style={{ backgroundColor: '#2E8B57' }}><HomeIcon fontSize="small" /></G.IconButton>
                    <G.IconButton style={{ backgroundColor: '#E95839' }}><BackupIcon fontSize="small" /></G.IconButton>
                    <G.IconButton style={{ backgroundColor: '#FCC703' }}><ChatBubbleRoundedIcon fontSize="small" /></G.IconButton>
                  </G.Text>
                </G.FieldArea>
              </G.TableArea>
            ))}
          </G.TableBody>
        </G.Box>
      </G.Body>
      <G.Footer>
        <HealthAndSafetyIcon />
        <p> Todos os direitos reservados. </p>
        <HealthAndSafetyIcon />
      </G.Footer>
    </C.Container>
  );
}
