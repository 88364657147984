import styled from 'styled-components';

export const Container = styled.div`
position: absolute;
transition: all 0.2s ease;
top: 0;
right: 0;
border-radius: 10px 0px 0px 10px;
margin-top: 100px;
width: ${props => props.status ? "330px" : "0px"};
height: 50px;
background-color: ${props => props.color}; 
font-size: 15px;
color: #000;
display: flex;
justify-content: space-evenly;
align-items: center;
`;
