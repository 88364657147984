import React from 'react';

import { MainRoute } from './routes/MainRoute';
import UserContextProvider from './contexts/UserContext'


const App = () => {

    return (
      <UserContextProvider>
        <MainRoute>
        </MainRoute>
      </UserContextProvider>
    );
}

export default App
